import '../Styles/login.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


const Login = () => {
  return (
    <div className='login-container'>
      <div className='login-form'>

        <Form>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Control type="email" placeholder="Enter email" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupPassword">
            <Form.Control type="password" placeholder="Password" />
          </Form.Group>

          <Button className='login-button' size="lg" variant="primary" type="submit">
            Login
          </Button>
        </Form>

      </div>
    </div>
  );
};

export default Login;