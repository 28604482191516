import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import logo from '../Assets/CKlogo.png';
import '../Styles/navbar.css';

const NavBar = () => {
    return (
        <Navbar id="navbar" bg="dark" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand className="brand-name" href="/"><img
            alt=""
            src={logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
          />{' '}Chef Katers</Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                Chef Katers
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/login">Login</Nav.Link>
                <Nav.Link href="/get-started">Get Started</Nav.Link>
                {/* <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Delivery Address"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="secondary" size="sm">Search</Button>
                </Form> */}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    );
  };
    
  export default NavBar;