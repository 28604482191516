import '../Styles/results.css';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import image1 from '../Assets/food-image1.jpg';
import icon1 from '../Assets/icon1.jpg';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { faStar as faStarReg } from "@fortawesome/free-regular-svg-icons"
import { useNavigate } from 'react-router-dom';


const Results = () => {
  let navigate = useNavigate(); 
  const routeChange = (path) => {
    navigate(path);
  }

  return (
    <div className='results-page'>
      <div className="card-container-results">
        <CardGroup className='promo-cards'>
          <Card>
            <Card.Body>
              <Card.Title>Promotion</Card.Title>
              <Card.Text>
                $7.99/month Get free delivery.
              </Card.Text>
              <Button className='card-button' variant="primary">Order Now</Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Promotion</Card.Title>
              <Card.Text>
                $7.99/month Get free delivery.
              </Card.Text>
              <Button className='card-button' variant="primary">Order Now</Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Promotion</Card.Title>
              <Card.Text>
                $7.99/month Get free delivery.
              </Card.Text>
              <Button className='card-button' variant="primary">Order Now</Button>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      <Carousel variant="dark">
        <Carousel.Item>
          <div className="card-container-results">
            <CardGroup className='carousel-cards'>
              <Card>
                <Card.Body>
                  <Card.Title>Offers</Card.Title>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Weddings</Card.Title>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Fast Food</Card.Title>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Meal Plans</Card.Title>
                </Card.Body>
              </Card>
            </CardGroup>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="card-container-results">
            <CardGroup className='carousel-cards'>
              <Card>
                <Card.Body>
                  <Card.Title>Offers</Card.Title>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Weddings</Card.Title>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Fast Food</Card.Title>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Meal Plans</Card.Title>
                </Card.Body>
              </Card>
            </CardGroup>
          </div>
        </Carousel.Item>
      </Carousel>
      <div className='filter-container'>
        <Container>
          <Row>
            <Col>
              <h3>
                Schedule Order
              </h3>
              <p>Click here to select a date and time</p>
              <h3>
                Sort
              </h3>
              <Form>
                <Form.Check
                  type='radio'
                  id='filter-radio'
                  name='group1'
                  label='Most Popular'
                />
                <Form.Check
                  type='radio'
                  id='filter-radio'
                  name='group1'
                  label='Rating'
                />
                <Form.Check
                  type='radio'
                  id='filter-radio'
                  name='group1'
                  label='Distance'
                />
                <Form.Check
                  type='radio'
                  id='filter-radio'
                  name='group1'
                  label='Price'
                />
              </Form>
              <h3>
                Party Size
              </h3>
              <Form>
                <>
                  <Form.Range />
                </>
              </Form>
              <h3>
                Distance
              </h3>
              <Form>
                <>
                  <Form.Range />
                </>
              </Form>
              <h3>
                Cuisines
              </h3>
              <Form>
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Indian'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Mexican'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Fast Food'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Fine Dining'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Dessert'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Asian'
                />
              </Form>
              <h3>
                Dietary
              </h3>
              <Form>
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Halal'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Vegan'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Gluten Free'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Kosher'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Vegetarian'
                />
              </Form>
              <h3>
                Delivery
              </h3>
              <Form>
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Pick up and delivery'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Pick up only'
                />
                <Form.Check
                  type='checkbox'
                  id='filter-checkbox'
                  label='Delivery only'
                />
              </Form>
            </Col>
            <Col>
              <Table>
                <tbody>
                  <tr>
                    <div className="results-cards">
                      <Card onClick={() => routeChange("/restaurant")}>
                        <Card.Body >
                        <div className="result-container">
                        <img className="result-image"
                            src={image1}
                            alt="Third slide"/>
                        </div>
                        <div>
                        <Card.Title>Bonton, Indian Catering | Milton, ON (14.3KM)</Card.Title>
                          <Card.Text>
                            <Row>
                            <Col>
                            <img className="result-image-icon"
                            src={icon1}
                            alt="Third slide"/>
                            <div className="result-text">
                              Same day pickup <br></br>
                              Delivery available
                            </div>
                            </Col>
                            <Col>
                            <div className="result-text">
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStarReg}/>
                              <br/>
                              $$$ <br/>
                              Minimum 4 hours notice <br/>
                              Order size: 20-500
                            </div>
                            </Col>
                            </Row>
                          </Card.Text>
                        </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </tr>
                  <tr>
                    <div className="results-cards">
                      <Card onClick={() => routeChange("/restaurant")}>
                        <Card.Body>
                        <div className="result-container">
                        <img className="result-image"
                            src={image1}
                            alt="Third slide"/>
                        </div>
                        <div>
                        <Card.Title>Bonton, Indian Catering | Milton, ON (14.3KM)</Card.Title>
                          <Card.Text>
                            <Row>
                            <Col>
                            <img className="result-image-icon"
                            src={icon1}
                            alt="Third slide"/>
                            <div className="result-text">
                              Same day pickup <br></br>
                              Delivery available
                            </div>
                            </Col>
                            <Col>
                            <div className="result-text">
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStarReg}/>
                              <br/>
                              $$$ <br/>
                              Minimum 4 hours notice <br/>
                              Order size: 20-500
                            </div>
                            </Col>
                            </Row>
                          </Card.Text>
                        </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </tr>
                  <tr>
                    <div className="results-cards">
                      <Card onClick={() => routeChange("/restaurant")}>
                        <Card.Body>
                        <div className="result-container">
                        <img className="result-image"
                            src={image1}
                            alt="Third slide"/>
                        </div>
                        <div>
                        <Card.Title>Bonton, Indian Catering | Milton, ON (14.3KM)</Card.Title>
                          <Card.Text>
                            <Row>
                            <Col>
                            <img className="result-image-icon"
                            src={icon1}
                            alt="Third slide"/>
                            <div className="result-text">
                              Same day pickup <br></br>
                              Delivery available
                            </div>
                            </Col>
                            <Col>
                            <div className="result-text">
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStarReg}/>
                              <br/>
                              $$$ <br/>
                              Minimum 4 hours notice <br/>
                              Order size: 20-500
                            </div>
                            </Col>
                            </Row>
                          </Card.Text>
                        </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </tr>
                  <tr>
                    <div className="results-cards">
                      <Card onClick={() => routeChange("/restaurant")}>
                        <Card.Body>
                        <div className="result-container">
                        <img className="result-image"
                            src={image1}
                            alt="Third slide"/>
                        </div>
                        <div>
                        <Card.Title>Bonton, Indian Catering | Milton, ON (14.3KM)</Card.Title>
                          <Card.Text>
                            <Row>
                            <Col>
                            <img className="result-image-icon"
                            src={icon1}
                            alt="Third slide"/>
                            <div className="result-text">
                              Same day pickup <br></br>
                              Delivery available
                            </div>
                            </Col>
                            <Col>
                            <div className="result-text">
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStarReg}/>
                              <br/>
                              $$$ <br/>
                              Minimum 4 hours notice <br/>
                              Order size: 20-500
                            </div>
                            </Col>
                            </Row>
                          </Card.Text>
                        </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </tr>
                  <tr>
                    <div className="results-cards">
                      <Card onClick={() => routeChange("/restaurant")}>
                        <Card.Body>
                        <div className="result-container">
                        <img className="result-image"
                            src={image1}
                            alt="Third slide"/>
                        </div>
                        <div>
                        <Card.Title>Bonton, Indian Catering | Milton, ON (14.3KM)</Card.Title>
                          <Card.Text>
                            <Row>
                            <Col>
                            <img className="result-image-icon"
                            src={icon1}
                            alt="Third slide"/>
                            <div className="result-text">
                              Same day pickup <br></br>
                              Delivery available
                            </div>
                            </Col>
                            <Col>
                            <div className="result-text">
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStar}/>
                              <FontAwesomeIcon icon={faStarReg}/>
                              <br/>
                              $$$ <br/>
                              Minimum 4 hours notice <br/>
                              Order size: 20-500
                            </div>
                            </Col>
                            </Row>
                          </Card.Text>
                        </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Results;