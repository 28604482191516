import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import '../Styles/getstarted.css';
import React from 'react';

const GetStarted = () => {

  const [showForm, setShowForm] = new React.useState(false);

  const onClick = () => setShowForm(true)

  return (
    <div className="get-started-page">
      <div className="get-started-cards">
        {!showForm ?
        <Row sm={1} md={1} lg={2}>
          <Col className='card-column'>
            <Card>
              <Card.Body>
                <Card.Title>Customer</Card.Title>
                <Card.Text>
                  <ul className='card-text-bullets'>
                    <li>Cater for any occasion</li>
                    <li>Better variety, more convenience</li>
                    <li>CK Rewards</li>
                  </ul>
                </Card.Text>
                <Button className='card-button' variant="primary" onClick={onClick}>Sign Up</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className='card-column'>
            <Card>
              <Card.Body>
                <Card.Title>Katerer</Card.Title>
                <Card.Text>
                  <ul className='card-text-bullets'>
                    <li>Boost Your Food Brand's Growth</li>
                    <li>Tap into the Growing Online Ordering Market</li>
                    <li>Extend Your Customer Reach with Our Platform</li>
                  </ul>
                </Card.Text>
                <Button href='/vendor-sign-up' className='card-button' variant="primary">Sign Up</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row> : 
        <Row sm={1} md={1} lg={2}>
          <Col className='card-column'>
            <Card>
              <Card.Body>
                <Card.Title>Registration</Card.Title>
                <Card.Text>
                  <Form>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                      <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                      <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                      <Form.Control type="password" placeholder="Confirm Password" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                      <Form.Control type="text" placeholder="First Name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                      <Form.Control type="text" placeholder="Last Name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                      <Form.Control type="text" placeholder="Phone Number" />
                    </Form.Group>
                    <Button className='login-button' size="lg" variant="primary" type="submit">
                      Sign Up
                    </Button>
                  </Form>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>}
      </div>
    </div>
  );
};

export default GetStarted;