import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faInstagram, faFacebook, faTiktok } from "@fortawesome/free-brands-svg-icons"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import '../Styles/footer.css';


const Footer = () => {
    return (
        <div className="footer">
        <Container>
            <Row className='footer-row'>
                <Col>
                    <p className="footer-brand">Chef Katers</p>
                    <p className="footer-copyright"> @ 2023 Chef Katers Canada, Inc. All rights reserved. </p>
                    {/* <Table hover>
        <tbody>
        <tr>
            <Button className='footer-buttons' variant="outline-light">Apple App Store</Button>
          </tr>
          <tr>
            <Button className='footer-buttons' variant="outline-light">Android App Store</Button>
          </tr>
        </tbody>
      </Table> */}
                </Col>
                <Col>
                    <Table className="footer-links" hover>
                        <tbody>
                            <tr>
                                <a href='/about-us'>About Us</a>
                            </tr>
                            <tr>
                                <a href='/meet-the-team'>Meet the team</a>
                            </tr>
                            <tr>
                                <a href='/locations'>Available locations</a>
                            </tr>
                            <tr>
                                <a href='/careers'>Careers</a>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table className="footer-links" hover>
                        <tbody>
                            <tr>
                                <a href='/partner-with-us'>Partner with Chef Katers</a>
                            </tr>
                            <tr>
                                <a href='/ck-pass'>CK Pass</a>
                            </tr>
                            <tr>
                                <a href='/customer-support'>Customer Support</a>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table className="footer-links" hover>
                        <tbody>
                            <tr>
                                <a href='#'>Accessibility</a>
                            </tr>
                            <tr>
                                <a href='#'>Terms & Conditions</a>
                            </tr>
                            <tr>
                                <a href='#'>Privacy</a>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <FontAwesomeIcon className="footer-icon" icon={faTwitter} size="2x" color='white' />
                    <FontAwesomeIcon className="footer-icon" icon={faInstagram} size="2x" color='white' />
                    <FontAwesomeIcon className="footer-icon" icon={faFacebook} size="2x" color='white' />
                    <FontAwesomeIcon className="footer-icon" icon={faTiktok} size="2x" color='white' />
                        {/* <tbody>
                            <tr>
                                <FontAwesomeIcon icon={faTwitter} size="2x" color='white' />
                            </tr>
                            <tr>
                                <FontAwesomeIcon icon={faInstagram} size="2x" color='white' />
                            </tr>
                            <tr>
                                <FontAwesomeIcon icon={faFacebook} size="2x" color='white' />
                            </tr>
                            <tr>
                                <FontAwesomeIcon icon={faTiktok} size="2x" color='white' />
                            </tr>
                        </tbody> */}
                    {/* <Button variant="outline-light">Twitter</Button>
    <Button variant="outline-light">Instagram</Button>
    <Button variant="outline-light">Facebook</Button>
    <Button variant="outline-light">Tik Tok</Button> */}
                </Col>
            </Row>
        </Container>
    </div>
    );
  };
    
  export default Footer;