import '../Styles/restaurant.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import image1 from '../Assets/menu-image1.jpg';
import image2 from '../Assets/menu-image2.jpg';
import image3 from '../Assets/menu-image3.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { faStar as faStarReg } from "@fortawesome/free-regular-svg-icons"

const Restaurant = () => {
  return (
    <div className="restaurant-container">
      <div className="banner">
      </div>
      <div className="info">
        <h3>
          Bonton, Indian Catering | Milton, ON (14.3KM)
        </h3>
        <h4>
        <span>
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStarReg} />
          </span>
          | $$$ | Minimum 4 hours notice | Order size: 20-500
        </h4>
      </div>
      <div className="menu">
        <Row>
          <Col className="menu-links">
            <h3>
              Repeat Order
            </h3>
            <h3>
              Appetizers
            </h3>
            <h3>
              Mains
            </h3>
            <h3>
              Sides
            </h3>
            <h3>
              Dessert
            </h3>
            <h3>
              Drinks
            </h3>
          </Col>
          <Col className="menu-items">
            <Row md={3} lg={6}>
            <Card>
                <Card.Body>
                  <Card.Img className='' variant="top" src={image1} />
                  <Card.Title>Samosa</Card.Title>
                  <Card.Text>
                    $1.00/item
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Img className='' variant="top" src={image2} />
                  <Card.Title>Spring Roll</Card.Title>
                  <Card.Text>
                    $0.50/item
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Img className='' variant="top" src={image3} />
                  <Card.Title>Channa Chaat</Card.Title>
                  <Card.Text>
                    $5.00/portion
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Img className='' variant="top" src={image3} />
                  <Card.Title>Pakora</Card.Title>
                  <Card.Text>
                    $0.50/portion
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Img className='' variant="top" src={image3} />
                  <Card.Title>Pani Puri</Card.Title>
                  <Card.Text>
                    $2.00/portion
                  </Card.Text>
                </Card.Body>
              </Card>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Restaurant;