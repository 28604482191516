import slide1 from '../Assets/event.svg';
import slide2 from '../Assets/variety.svg';
import slide3 from '../Assets/rewards.svg';
import cardimage1 from '../Assets/register.svg';
import cardimage2 from '../Assets/pass.svg';
import cardimage3 from '../Assets/careers.svg';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import '../Styles/home.css';

const Home = () => {
    return (
        <div className='home-container'>
            <div className="hero-banner">
                <h1 className="slogan">The future of catering</h1>
                <div className="search-box">
                    <Form className='top-margin'>
                        <Form.Control
                            className='lg-2'
                            type="search"
                            placeholder="Delivery Address" />
                        <Button href="/results" className="top-margin search-button" variant="primary" size="md">
                            <span class="desktop-text">Find catering in your area</span>
                            <span class="mobile-text">Find catering</span>
                        </Button>
                    </Form>
                </div>
            </div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className="carousel-slide">
                        <div className="carousel-info">
                            <h2 className="carousel-heading">Cater for any occasion</h2>
                            <p className="carousel-text">Our chefs are ready to cater for your needs. Catering for any party size, cuisine type and more. </p>
                        </div>
                        <img
                            className="carousel-image"
                            src={slide1}
                            alt="First slide"
                        />
                    </div>
                    {/* <Carousel.Caption>
            <h2>Help grow your food brand</h2>
            <p>Through our service your brand will grow and gain traction from new customers and event planners. </p>
          </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <div className="carousel-slide">
                        <div className="carousel-info">
                            <h2 className="carousel-heading">Better variety, more convenience</h2>
                            <p className="carousel-text">All your favourite caterers in one place allowing you to compare and choose with the most convenience. </p>
                        </div>
                        <img
                            className="carousel-image"
                            src={slide2}
                            alt="Second slide"
                        />
                    </div>
                    {/* <Carousel.Caption>
            <h2>Expand your online ordering business</h2>
            <p>Tap into a market with increasing demand for catering food. </p>
          </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <div className="carousel-slide">
                        <div className="carousel-info">
                            <h2 className="carousel-heading">Chef Katers Rewards</h2>
                            <p className="carousel-text">
                                Be part of the CK community to get rewarded and save you more money when ordering food.
                            </p>
                        </div>
                        <img
                            className="carousel-image"
                            src={slide3}
                            alt="Third slide"
                        />
                    </div>
                    {/* <Carousel.Caption>
            <h2>Increased customer reach using our platform</h2>
            <p>
            Allow people from different areas to see your business and only pay on item sales, who doesn’t love free marketing?
            </p>
          </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>
            <div className="card-container-home">
                <CardGroup className='cards'>
                    <Card>
                        <Card.Img className='card-image' variant="top" src={cardimage1} />
                        <Card.Body>
                            <Card.Title>Register with Chef Katers</Card.Title>
                            <Card.Text>
                                Partner your restaurant with us to grow your business and reach new customers.
                            </Card.Text>
                            <Button className='card-button' variant="primary">Learn More</Button>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Img className='card-image' variant="top" src={cardimage2} />
                        <Card.Body>
                            <Card.Title>Chef Katers Pass</Card.Title>
                            <Card.Text>
                                Recieve exclusive perks for our monthly subscription members. Get benefits such as $0 delivery fee.
                            </Card.Text>
                            <Button className='card-button' variant="primary">Join Now</Button>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Img className='card-image' variant="top" src={cardimage3} />
                        <Card.Body>
                            <Card.Title>Career Opportunities</Card.Title>
                            <Card.Text>
                                Join the team and inspire the workplace. Bring your creativity to a fun, challenging, and fast-paced work environment.
                            </Card.Text>
                            <Button className='card-button' variant="primary">Apply Today</Button>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </div>
        </div>
    );
};

export default Home;