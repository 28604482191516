import '../Styles/aboutus.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <h2>About Us</h2>
      <h3> Who we are</h3>
      <p>
        Welcome to Chef Katers, your premier online food-cater ordering platform based in Toronto, Ontario, Canada. We are passionate about connecting local caterers with consumers, providing a seamless and convenient way to fulfill all your catering needs.

        At Chef Katers, we understand the importance of ease, variety, and quality when it comes to catering services. Our platform is designed to empower local catering businesses, allowing them to showcase their culinary expertise and offer their delectable creations to a wide range of customers. Whether you're hosting a large gathering or simply seeking a delicious home-cooked meal without the hassle of cooking, we've got you covered.

        Our dedicated team is working tirelessly to develop an innovative search engine that will revolutionize the way you find the perfect Chef Kater for your event or meal. With our intuitive filters, you can easily browse through a diverse selection of cuisines, consider the number of people to be served, choose your preferred location, and more. We believe that finding the ideal caterer should be a stress-free experience, and our user-friendly search tool ensures just that.

        Our mission is to provide a platform that not only supports local catering businesses but also delights consumers with an extensive range of culinary options. By collaborating with talented chefs and caterers, we aim to offer a diverse menu, catering to various tastes, dietary preferences, and cultural backgrounds. Through Chef Katers, we want to make it effortless for you to discover and indulge in the finest culinary creations from your local community.

        Whether you're planning a corporate event, a wedding celebration, a family gathering, or simply craving a delicious meal, Chef Katers is here to make it happen. Join us on this culinary journey and experience the convenience, variety, and excellence of our online food-cater ordering platform.

        Thank you for choosing Chef Katers. We look forward to serving you a memorable dining experience!
      </p>
      <h3>How Chef Katers Work</h3>
      <h5>1. Explore</h5>
      <p>
        Discover an extensive selection of top-quality restaurants and catering partners through Chef Katers. Whether you are planning a birthday party, a wedding, or a game night, we have you covered. Take advantage of our intuitive browsing feature or utilize the search function to find a specific restaurant or caterer. Once you have found what you're looking for, simply tap to add it to your cart.
      </p>
      <h5>2. Place an Order</h5>
      <p>
        Once you have finished browsing and added all your desired catering items to your cart, you will be presented with a comprehensive order summary. This summary includes the total price, the designated pick-up or delivery address, as well as the specified date and time for your order. When you have reviewed and ensured the accuracy of the order details, proceed by selecting the "Place Order" button and complete the secure payment process.
      </p>
      <h5>3. Track Your Order</h5>
      <p>
        Stay informed about the progress of your order by utilizing our convenient order tracking page. Receive timely notifications and updates regarding the status of your order, including notifications when it is ready for pick-up or scheduled for delivery.
      </p>
    </div>
  );
};

export default AboutUs;
